import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'types/api/chat.model'
import {
	Conversation,
	ConversationList,
	Message,
} from 'types/api/message.model'
import {
	ICreateMessageMutation,
	IMetricsMessage,
} from 'services/messages/messages.types'
import { getSelectedMessages } from 'services/messages/messages.service'
import createAsyncReducers from 'utils/create-async-reducers'
import {
	createMessage,
	scheduleMessage,
	listScheduleMessages,
	updateScheduleMessageTime,
	updateScheduleMessage,
	archiveScheduleMessage,
	sendMessage,
	getMessages,
	searchMessage,
	createConversation,
	getMessageMetrics,
	readMessage,
	listConversations,
	searchListMessage,
	fetchConversations,
	fetchCombinedConversations,
	getConversation,
	updateSpamConversation,
	getConversationLink,
	getMessage,
} from './messages.actions'
import { Contact } from 'types/api/contact.model'
import { ScheduleDateType } from 'types/utils/scheduleDate.type'
export interface IState {
	newMessage: boolean
	selectedConversation: Chat
	inboxConversations: Chat[]
	combinedConversations: Chat[]
	inboxConversationsLoading: boolean
	conversationList: ConversationList
	combinedConversationsLoading: boolean
	inboxConversationsTypeList: 'inbox' | 'unknown'
	conversationListType: 'inbox' | 'campaign'
	messagesList: Message[]
	lastMessage?: Message
	polledMessage?: Message | undefined
	message: Message[]
	reScheduledMessage: Message
	metrics: IMetricsMessage
	loading: boolean
	loadingSearch: boolean
	loadingMetrics: boolean
	loadingMessagesList: boolean
	loadingConversation: boolean
	loadingMessage: boolean
	defaultChatId: string
	currentConversation?: Conversation
	endDate: Date
	scheduledMessages: Message[]
	scheduledMessagesLoading: boolean
	dateAndTime: ScheduleDateType
}

export type MessageFields = {
	input: ICreateMessageMutation
}

const currentDate = new Date(Date.now())
const currentHour = (currentDate.getHours() % 12 || 12)
	.toString()
	.padStart(2, '0')
const currentMinute = currentDate.getMinutes().toString().padStart(2, '0')
const currentPeriod = currentHour >= '12' ? 'PM' : 'AM'

const initialState: IState = {
	newMessage: false,
	selectedConversation: {} as Chat,
	inboxConversations: [],
	combinedConversations: [],
	inboxConversationsLoading: false,
	combinedConversationsLoading: false,
	inboxConversationsTypeList: 'inbox',
	conversationList: { conversations: [], totalConversations: 0 },
	messagesList: [],
	lastMessage: {},
	message: [],
	reScheduledMessage: {} as Message,
	metrics: {},
	loading: false,
	loadingSearch: false,
	loadingMetrics: false,
	loadingMessagesList: false,
	loadingMessage: false,
	loadingConversation: false,
	conversationListType: 'inbox',
	defaultChatId: '',
	currentConversation: undefined,
	endDate: new Date(),
	scheduledMessages: [],
	scheduledMessagesLoading: false,
	dateAndTime: {
		date: currentDate,
		hours: currentHour,
		minutes: currentMinute,
		period: currentPeriod,
	} as ScheduleDateType,
}

const { reducer, actions } = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		openNewMessage: (state, action: PayloadAction<boolean>) => {
			state.newMessage = action.payload
		},
		setSelectedConversation: (state, action: PayloadAction<Chat>) => {
			state.selectedConversation = action.payload
		},
		setInboxConversations: (state, action: PayloadAction<Chat[]>) => {
			state.inboxConversations = action.payload
		},
		setPolledMessage: (state, action: PayloadAction<Message>) => {
			state.polledMessage = action.payload
		},
		resetPolledMessage: state => {
			state.polledMessage = undefined
		},
		setInboxConversationsPosition: (
			state,
			action: PayloadAction<{ new: Chat; olds: Chat[] }>
		) => {
			state.inboxConversations = [action.payload.new, ...action.payload.olds]
		},
		setInboxConversationsTypeList: (
			state,
			action: PayloadAction<'inbox' | 'unknown'>
		) => {
			state.inboxConversationsTypeList = action.payload
		},
		setConversationListType: (
			state,
			action: PayloadAction<'inbox' | 'campaign'>
		) => {
			state.conversationListType = action.payload
		},
		setMessage: (state, action: PayloadAction<Message[]>) => {
			state.message = action.payload
		},
		setDefaultChatId: (state, action: PayloadAction<string>) => {
			state.defaultChatId = action.payload
		},
		clearCurrentConversation: state => {
			state.currentConversation = undefined
		},
		setEndDate: (state, action: PayloadAction<Date>) => {
			state.endDate = action.payload
		},
		setDateAndTime: (state, action: PayloadAction<ScheduleDateType>) => {
			state.dateAndTime = action.payload
		},
		setRescheduledMessage: (state, action: PayloadAction<Message>) => {
			state.reScheduledMessage = action.payload
		},
		removeScheduledMessage: (state, action: PayloadAction<{}>) => {
			state.reScheduledMessage = {} as Message
		},
		// For Updating Contact across the slice
		setUpdatedContact: (state, action: PayloadAction<Contact>) => {
			state.conversationList = {
				totalConversations: state.conversationList.totalConversations,
				conversations: [
					...state.conversationList.conversations.map(convo =>
						convo.contact?.id === action.payload.id
							? {
									...convo,
									contact: action.payload,
							  }
							: convo
					),
				],
			}

			state.inboxConversations = [
				...state.inboxConversations.map(convo =>
					convo.contact?.id === action.payload.id
						? { ...convo, contact: action.payload }
						: convo
				),
			]
		},
	},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getMessages,
			'loading',
			(state, action: PayloadAction<Chat[]>) => {
				state.inboxConversations = action.payload
			}
		)
		createAsyncReducers(builder, readMessage, 'loading')
		createAsyncReducers(
			builder,
			createConversation,
			'loading',
			(state, action) => {
				state.currentConversation = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getConversationLink,
			'loading',
			(state, action) => {
				if (action.payload.isNew)
					state.currentConversation = action.payload.conversation
			}
		)
		createAsyncReducers(
			builder,
			updateSpamConversation,
			'loading',
			(state, action) => {
				state.currentConversation = action.payload
			}
		)
		createAsyncReducers(builder, createMessage, 'loading', (state, action) => {
			state.lastMessage = action.payload
		})
		createAsyncReducers(
			builder,
			scheduleMessage,
			'scheduledMessagesLoading',
			(state, action) => {
				state.scheduledMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			listScheduleMessages,
			'scheduledMessagesLoading',
			(state, action) => {
				state.scheduledMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateScheduleMessageTime,
			'scheduledMessagesLoading',
			(state, action) => {
				state.scheduledMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateScheduleMessage,
			'scheduledMessagesLoading',
			(state, action) => {
				state.scheduledMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			archiveScheduleMessage,
			'scheduledMessagesLoading',
			(state, action) => {
				state.scheduledMessages = action.payload
			}
		)
		createAsyncReducers(builder, sendMessage, 'loading', (state, action) => {
			state.lastMessage = action.payload
		})
		createAsyncReducers(
			builder,
			searchMessage,
			'loadingSearch',
			(state, action) => {
				state.message = action.payload
			}
		)
		createAsyncReducers(builder, getConversation, 'loadingConversation')
		createAsyncReducers(
			builder,
			getMessageMetrics,
			'loadingMetrics',
			(state, action) => {
				state.metrics = action.payload
			}
		)
		createAsyncReducers(
			builder,
			fetchConversations,
			'inboxConversationsLoading',
			(state, action) => {
				state.inboxConversations = [
					...state.inboxConversations,
					...action.payload,
				]
			}
		)
		createAsyncReducers(
			builder,
			listConversations,
			'inboxConversationsLoading',
			(state, action) => {
				state.conversationList = {
					...state.conversationList,
					...action.payload,
				}
			}
		)
		createAsyncReducers(
			builder,
			fetchCombinedConversations,
			'combinedConversationsLoading',
			(state, action) => {
				state.combinedConversations = [
					...state.combinedConversations,
					...action.payload,
				]
			}
		)
		createAsyncReducers(
			builder,
			searchListMessage,
			'loadingMessagesList',
			(state, action) => {
				state.messagesList = action.payload
			}
		)
		createAsyncReducers(builder, getMessage, 'loadingMessage')
	},
})

export default reducer

export const messagesActions = {
	...actions,
	getSelectedMessages,
	createConversation,
	readMessage,
	createMessage,
	scheduleMessage,
	listScheduleMessages,
	updateScheduleMessageTime,
	updateScheduleMessage,
	archiveScheduleMessage,
	sendMessage,
	getMessages,
	getConversation,
	searchMessage,
	getMessageMetrics,
	searchListMessage,
	fetchConversations,
	listConversations,
	fetchCombinedConversations,
	updateSpamConversation,
	getMessage,
}
